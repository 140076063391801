var consoleLog = true;
function joss(msg) {
	if(consoleLog) {
		console.log(msg);
	}
}

// screen size
var screenWidth = window.innerWidth;
var screenHeight = window.innerHeight;
window.addEventListener("resize", function(){
	screenWidth = window.innerWidth;
	screenHeight = window.innerHeight;
}, true);

// is.js vars
var isMob = is.mobile();
var isDesk = is.desktop();
var isIe = is.ie();
var isEdge = is.edge();

// some variable
var theBody = $('body');

var transitionDelay;
if (isDesk && screenWidth > 991 && screenHeight > 799 || isIe || isEdge) {
	transitionDelay = 1600;
} else {
	transitionDelay = 500;
}
var theNav = $('.header-top');
var theBrand = $('.brand');

var deskNav = $('.deskNav');
var noFullPage;

var curUrl;

var hasFullPage;

// -------------------------------
// pageHasFullPage
function pageHasFullPage() {
	if (theBody.hasClass('firstLoad')) {
		setTimeout(function(){ 
			$(theBody).removeClass('firstLoad');
			$(theBody).removeClass('ajax-animation-begin');
			$(theBody).addClass('ajax-animation-done');

			startFullPage();

			joss('- First page load, removed class on body');
		}, 500)
	} else {
		startFullPage();

		joss('- Not first page load');
	}
}

// -------------------------------
// startFullPage
function startFullPage() {
	var result = curUrl.indexOf('/nous-joindre') > -1;
	if ((isDesk && screenWidth > 991 && screenHeight > 799 || isIe || isEdge) && !result ) {

		hasFullPage = true;

		$('#fullpage').fullpage( {

			onLeave: function(index, nextIndex, direction){
				joss(index);
				var leavingSection = $(this);
				var notActive = 'not-active';

				leavingSection.addClass(notActive)
				if (direction == 'down') {
					leavingSection.next().removeClass(notActive);
				} else {
					if (leavingSection.prev().hasClass(notActive)) {
						leavingSection.prev().removeClass(notActive);
					}
				}

				if(index >= 1){
					theNav.addClass('has-scroll');
				}

				if (direction == 'up' && index <= 2) {
					theNav.removeClass('has-scroll');
				}
			},

			//Custom selectors
			sectionSelector: '.scene',

			//Design
			paddingTop: '100px',
			easingcss3: 'cubic-bezier(0.43, 0.045, 0.1, 0.95)',
			scrollOverflow: true,

			//Accesibility
			recordHistory: true,
			autoScrolling: true
		});

		$(theBody).addClass('fullScreen');
		// $(theBody).removeClass('firstLoad');

	} else {

		hasFullPage = false;

		$('body').addClass('smallScreen');
		joss('FullPage JS not active because we are not on a desktop or the screen width is not at least 991px');
	}
}

// -------------------------------
// get current scroll
function getCurrentScroll() {
	return window.pageYOffset || document.documentElement.scrollTop;
}

$(window).scroll(function() {
	var shrinkHeader = 5;
	var scroll = getCurrentScroll();
	if ( scroll >= shrinkHeader ) {
		$('.header-top').addClass('scroll-down');
	}
	else {
		$('.header-top').removeClass('scroll-down');
	}
});


function startContact() {
	var datvue = new Vue({
		el: '#fullpage',
		data: {
			type:'demande',
			sujet:'',
			division:'',
			projet: '',
			requerant: {
				salutation: 'm',
				prenom: '',
				nom: '',
				courriel: '',
				telephone: '',
				nociv: '',
				rue: '',
				unite: '',
				ville: '',
				codepostal: ''
			},
			requis: {
				same: false,
				nociv: '',
				rue: '',
				unite: '',
				ville: '',
				codepostal: ''
			},
			presente: {
				same: false,
				prenom: '',
				nom: '',
				telephone: ''
			},
			informations: {
				numero: '',
				annee: '',
				phase: '',
				nom: ''
			},
			description: '',
			recevoir: true,
		},
		methods: {
			submit: function(event) {
				var inpObj = document.getElementById("form-contact");
				var submit_button = $('#form-contact').find('button[type="submit"]');
				var span_loading_msg = submit_button.parent().find('span');
				$(".submiterror").addClass('hide');
				submit_button.addClass('disabled').text(span_loading_msg.text());
			}
		}
	});
}
	var contactsuccess = function(msg) {
		$("#submitsuccess").html(msg);
		$("#form-contact").hide().next().removeClass('hide').show();
		$('html,body').animate({
			scrollTop: $("#theContactForm").offset().top-100
		}, 1000);
	};

	var contacterror = function(msg_error) {
		$('#form-contact').find('button[type="submit"]').removeClass('disabled').text('Envoyer');
		$(".submiterror").html(msg_error).removeClass('hide');
	};
// -------------------------------
// check division
function checkDivision() {
	// if string contain /contemporain
	if ( curUrl.indexOf('/contemporain') !== -1) {
		theBrand.addClass('contemporain');
		joss('contemporain');
	} else{
		theBrand.removeClass('contemporain');
	}

	// if string contain /inc
	if ( curUrl.indexOf('/inc') !== -1) {
		theBrand.addClass('inc');
		joss('inc');
	} else{
		theBrand.removeClass('inc');
	}

	// if string contain /exp
	if ( curUrl.indexOf('/exp') !== -1) {
		theBrand.addClass('exp');
		joss('exp');
	} else{
		theBrand.removeClass('exp');
	}
}

// -------------------------------
// active correct nav item
function activeNavLink() {
	var child = deskNav.find('li');

	for (var i = child.length - 1; i >= 0; i--) {
		var item = $(child[i]).find('a');
		var slug = $(item).attr('href');

		$(item).removeClass('active');

		if (curUrl.indexOf('/actualites') !== -1 && slug == '/actualites') {
			$(item).addClass('active');
		}

		if (curUrl.indexOf('/produits') !== -1 && slug == '/produits') {
			$(item).addClass('active');
		}

		if (curUrl.indexOf('/carriere') !== -1 && slug == '/carriere') {
			$(item).addClass('active');
		}

		if (curUrl.indexOf('/nous-joindre') !== -1 && slug == '/nous-joindre') {
			$(item).addClass('active');
		}
	}
}

// -------------------------------
// trigger scroll next section
function scrollNext() {
	$('.Btn-down').click(function () {
		slideTo = $(this).attr('data-scroll');

		if (slideTo) {
			$.fn.fullpage.moveTo(slideTo);
		} else {
			$.fn.fullpage.moveSectionDown();
		}

	});
}

// -------------------------------
// trigger scroll next section
function scrollPrev() {
	$('.Btn-up').click(function () {
		slideTo = $(this).attr('data-scroll');

		if (slideTo) {
			$.fn.fullpage.moveTo(slideTo);
		} else {
			$.fn.fullpage.moveSectionUp();
		}
	});
}

//  ------------------------------
//  Material Design Labels
function startMaterialLabel() {
	var $MDLwrapper = $('.material-label'),
		InputSelector = 'input, select, textarea',
		$MDLinputs = $MDLwrapper.find(InputSelector),
		$MDLselects = $MDLwrapper.find('select');

	$MDLinputs.each(function() {
		var $this = $(this);

		if($this.val()) {
			$this.parents('.material-label').addClass('focus');
		}
	});

	$('body').on('focus',InputSelector, function() {
		$(this).parents('.material-label').addClass('focus');
	});

	$('body').on('blur', InputSelector,function() {
		var $this = $(this);

		if(!$this.val()) {
			$this.parents('.material-label').removeClass('focus');
		}
	});

	$MDLselects.on('change', function() {
		var $this = $(this);
		if(!$this.val()) {
			$this.parents('.material-label').removeClass('focus');
		}
	});

	$('.custom-select').customSelect();
}

//  ------------------------------
//  Detect is current
function checkBrowser() {
	if (isEdge == true) {
		$(theBody).addClass('no-blur');
	}
}

//  ------------------------------
//  anchor smooth scroll
function startSmoothScroll() {
	$('a[href*=#]:not([href=#])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
			|| location.hostname == this.hostname) {

			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top-100
				}, 1000);
				return false;
			}
		}
	});

	joss('- Started smmoth scroll');
}


//  -------------------------------------------------
//  -------------------------------------------------
//  AJAX STUFF

	// page transiton
	var HideShowTransition = Barba.BaseTransition.extend({
  		start: function() {
		    /**
		     * This function is automatically called as soon the Transition starts
		     * this.newContainerLoading is a Promise for the loading of the new container
		     * (Barba.js also comes with an handy Promise polyfill!)
		     */
			$(theBody).addClass('ajax-animation-begin');
		    // As soon the loading is finished and the old page is faded out, let's fade the new page
		    Promise
		      .all([this.newContainerLoading, this.slideOut()])
		      .then(this.slideIn.bind(this));
		  },

	  slideOut: function() {
		    /**
		     * this.oldContainer is the HTMLElement of the old Container
		     */
		     $(theBody).removeClass('ajax-animation-done');

		    return new Promise(function(resolve, reject) {
		        window.setTimeout(function() {
		           resolve();
		        }, transitionDelay);
		    });
		    // return $(theBody).on("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd").promise();
		  },

	  slideIn: function() {
		    /**
		     * this.newContainer is the HTMLElement of the new Container
		     * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
		     * Please note, newContainer is available just after newContainerLoading is resolved!
		     */
		     $(theBody).addClass('ajax-animation-done').removeClass('ajax-animation-begin');

		     this.done();

		      $('html, body').animate({
                scrollTop:$('body').offset().top
            }, 0);
		  }
	});

	/*
		ALL THE PAGES CLASSSSSSS

		------------------------
		------------------------
		- homepage -

		pageHome

		------------------------
		------------------------
		- onWhite -

		onWhite

		------------------------
		------------------------
		- onBlack -

		onBlack

		------------------------
		------------------------
		- onBlackDiv -

		onBlackDiv
	*/


	// ------------------------
	// ------------------------
	// namespace onWhite
	var onWhite = Barba.BaseView.extend({
	  namespace: 'onWhite',
	  onEnter: function() {
	    	// The new Container is ready and attached to the DOM.
	  },
	  onEnterCompleted: function() {
	    	$('body').addClass('onWhite');
	  },
	  onLeave: function() {
	      	// A new Transition toward a new page has just started.
	    	$('body').removeClass('onWhite');
	  },
	  onLeaveCompleted: function() {
	    	// The Container has just been removed from the DOM.
	  }
	});

	// ------------------------
	// ------------------------
	// namespace onBlack
	var onBlack = Barba.BaseView.extend({
	  namespace: 'onBlack',
	  onEnter: function() {
	    	// The new Container is ready and attached to the DOM.
	  },
	  onEnterCompleted: function() {
	    	$('body').addClass('onBlack');
	  },
	  onLeave: function() {
	    	// A new Transition toward a new page has just started.
	    	$('body').removeClass('onBlack');
	  },
	  onLeaveCompleted: function() {
	    	// The Container has just been removed from the DOM.
	  }
	});

	// ------------------------
	// ------------------------
	// namespace onBlackDiv
	var onBlackDiv = Barba.BaseView.extend({
	  namespace: 'onBlackDiv',
	  onEnter: function() {
	    	// The new Container is ready and attached to the DOM.
	  },
	  onEnterCompleted: function() {
	    	$('body').addClass('onBlackDiv');
	  },
	  onLeave: function() {
	    	// A new Transition toward a new page has just started.
	    	$('body').removeClass('onBlackDiv');
	  },
	  onLeaveCompleted: function() {
	    	// The Container has just been removed from the DOM.
	  }
	});


	// ------------------------
	// ------------------------
	// namespace homepage
	var homepage = Barba.BaseView.extend({
	  namespace: 'homepage',
	  onEnter: function() {
	   		// The new Container is ready and attached to the DOM.
	  },
	  onEnterCompleted: function() {
	    	$(theBody).addClass('pageHome');
	    	theBody.css('background', '');
	  },
	  onLeave: function() {
	    	// A new Transition toward a new page has just started.
	    	$(theBody).removeClass('pageHome');
	  },
	  onLeaveCompleted: function() {
	    	// The Container has just been removed from the DOM.
	  }
	});

	// init namescape
	homepage.init();
	onWhite.init();
	onBlack.init();
	onBlackDiv.init();

//  AJAX STUFF END
//  -------------------------------------------------
//  -------------------------------------------------



//  -------------------------------------------------
//  -------------------------------------------------
//  DOCUMENT READY
$(document).ready(function() {
	// init ajax with custom page transition
	Barba.Pjax.start();
	Barba.Pjax.getTransition = function() {
		return HideShowTransition;
	};


	//	-------------------------------------------------
	//	-------------------------------------------------
	// AJAX
	var theLink;

	// The user click on a link elegible for AJAX.
	Barba.Dispatcher.on('linkClicked', function() {
		joss('---------------------');
		joss('CLICKED');

		$('[data-toggle]').removeClass('is-active');
		$('[data-toggler]').removeClass('is-active');
		document.body.scrollTop = 0;
		if ($('#portfolio.featured').find('.items')) {
			$('#portfolio.featured').find('.items').slick('unslick');
		}

	});

	// the new container has been loaded and injected in the wrapper
	Barba.Dispatcher.on('newPageReady', function(currentStatus, prevStatus, HTMLElementContainer, newPageRawHTML) {
		joss('---------------------');
		joss('READY');

		theNav.removeClass('has-scroll');
		curUrl = Barba.Utils.getCurrentUrl();

		var $html = $(document.createElement("html"));
        $html[0].innerHTML = newPageRawHTML; // Here's where the "magic" happens

      	/*Changement du SEO*/
        var page_title = $html.find('title').text();
        var page_description = $html.find('meta[name="description"]').attr('content');
        var page_og_url = $html.find('meta[property="og:url"]').attr('content');
        var page_og_image = $html.find('meta[property="og:image"]').attr('content');
        var page_og_sitename = $html.find('meta[property="og:site_name"]').attr('content');

		$('meta[property="og:title"]').attr('content',page_title);
		$('meta[name="description"]').attr('content',page_description);
		$('meta[property="og:description"]').attr('content',page_description);
		$('meta[property="og:url"]').attr('content',page_og_url);
		$('meta[property="og:image"]').attr('content',page_og_image);
		$('meta[property="og:site_name"]').attr('content',page_og_sitename);

		checkDivision();

		activeNavLink();

		joss(curUrl);
	});

	// The transition has just finished and the
	// old Container has been removed from the DOM.
	Barba.Dispatcher.on('transitionCompleted', function() {
		joss('---------------------');
		joss('COMPLETED');

		if (isDesk && screenWidth > 991 && screenHeight > 799 || isIe || isEdge) {
    		$(theBody).removeClass('smallScreen');
    		joss('- remove smallScreen class');
		}

		startSlider();


		if (hasFullPage == true) {
			$.fn.fullpage.destroy('all');
		} else {
			startSmoothScroll()
		}

		pageHasFullPage();
		scrollNext();
		scrollPrev();
		startMaterialLabel();
		checkBrowser();
		if ( curUrl.indexOf('/nous-joindre') !== -1) {
			startContact();
			joss('nous-joindre');
		}
	});


	// trigger some ajax actions once on document ready
	curUrl = Barba.Utils.getCurrentUrl();

		pageHasFullPage();
		checkDivision();
		scrollNext();
		scrollPrev();
		activeNavLink();
		startMaterialLabel();
		checkBrowser();

		if (!hasFullPage) {
			startSmoothScroll();
		}
	
	// AJAX END
	//	-------------------------------------------------
	//	-------------------------------------------------



   	//	------,-------------------------------------------
	// FORM Utilities
	// when upload a file, get it's name and display it in the label
	$('body').on('change','input[type="file"]',function (e) {
	    var fileName = e.target.files[0].name;

	    $(this).parent().find('label').text(fileName);
	});

	// when submiting a form, disable the button and show "sending..." in in
	/*$('form').submit(function (e) {
	    var submit_button = $(this).find('button[type="submit"]');
	    var span_loading_msg = submit_button.parent().find('span');

	    submit_button.addClass('disabled').text(span_loading_msg.text());
	});*/

	//  -------------------------------------------------
	//  ACCORDIONs - Slide down - up
	var accordion = $(".Accordions");
	var accordion_length = accordion.length;

	if (accordion_length > 0) {

		var block = $(".Accordions_block");
		var header = $(".Accordions_header");
		var title = $(".Accordions_title");
		var content = $(".Accordions_content");

		header.click(function(){

			var self = $(this);

			if (!accordion.hasClass('Accordions-collapse')) {
				var accordions_active = $('.Accordions_block .Accordions_header.active');

				accordions_active.removeClass('active');

				if (accordions_active.length > 0) {
					accordions_active.next().slideUp(function(){
						if (self[0] != $(this).prev()[0]) {
							slideAccordion('toggle', self);
						}
					});
				}
				else {
					slideAccordion('toggle', self);
				}
			}
			else {
				slideAccordion('toggle', self);
			}
		});

	};

	function slideAccordion(typeSlide,accordion) {
		switch(typeSlide.toLowerCase()) {
		    case 'up':
		        accordion.removeClass("active");
				accordion.next().slideUp(200);
		        break;
		    case 'down':
		        accordion.addClass("active");
				accordion.next().slideDown(200);
		        break;
		    case 'toggle':
		 		accordion.toggleClass("active");
				accordion.next().slideToggle(200);
		        break;
		}
	}
	if ($('.js-contactForm').length>0) {
		$('.js-contactForm').validate({
			errorPlacement: function(error,element) {
				$(element).parent().addClass('has-error');
				error.insertAfter($(element).parent());
			},
			success: function(label,element) {
				$(element).parent().find('label').first.remove();
				$(element).parent().removeClass('has-error');
			}
		});
	}

	$("#contact-type-select").on('change', function() {
		if (hasFullPage == true) {
			$.fn.fullpage.reBuild();
		}
	});
	$("body").on("change",".custom-select-wrapper select", function() {
		console.log('ALLO');
		$(this).parent().find(".customSelectInner").first().html($(this).find("option:selected").html());
	});
	//  -------------------------------------------------
	//  TOGGLE Utility that works
	// get the data-toggler and its value
	$('[data-toggler]').click(function() {
		var self = $(this);
		var mainValue = $(this).attr('data-toggler');

		// then execute the function to toggleClass to all its matching toggler
		TheToggler(mainValue, self);
	});

	function TheToggler(mainValue, self) {
		var on = 'is-active';

		// get all other toggler with the same value
		$('[data-toggler]').each(function(){
			if ($(this).attr('data-toggler') == mainValue) {
				$(this).toggleClass(on);
			}
		});

		// get all element that needs to be toggle
		$('[data-toggle]').each(function(){

			// check if matching value
			var value = $(this).attr('data-toggle');
			if (mainValue == value) {
				$(this).toggleClass(on);
			}
		});
	}


	$('body').on('click','#form-contact .btn',function() {
		$('#form-contact').attr('action', '/json?module=contactform&method=submit');
	});

	$("body").on("change","#contact-type-select", function() {
		$(this).parent().find(".customSelectInner").first().html($("#contact-type-select option:selected").html());
	});

	$("body").on("click",'.js-switchform',function() {
		var val = $(this).data('val');
		$('#contact-type-select option').each(function(){
			if( $(this).val() == val ) {
				$(this).prop('selected',true);
				var el = $("#contact-type-select").get(0);
				var event = document.createEvent('Events');
				event.initEvent('change', true, false);
				el.dispatchEvent(event);
			}
		});
		$('html,body').animate({
			scrollTop: $("#theContactForm").offset().top-100
		}, 1000);
		return false;
	})
	//  -------------------------------------------------
	//  -------------------------------------------------
	//  Slick
	function startSlider() {
		$('#portfolio.featured').find('.items'),
			portfolioNbItems = $('#portfolio.featured').find('.items').find('.item').length;

		$('#portfolio.featured').find('.items').on('init', function() {
			$('#portfolio.featured').find('.items').find('a.img').append('<div class="count"><div class="current">1</div><div class="total">' + portfolioNbItems + '</div></div>');
		});

		$('#portfolio.featured').find('.items').on('afterChange', function() {
			$('#portfolio.featured').find('.items').find('.count').html('<div class="current">' + ($('#portfolio.featured').find('.items').slick('slickCurrentSlide') + 1) + '</div><div class="total">' + portfolioNbItems + '</div>')
		});

		$('#portfolio.featured').find('.items').slick({
			fade: true,
			infinite: true,
			nextArrow: '<button type="button" class="btn btn-direction slick-next"><span><i class="fa fa-long-arrow-right"></i></span></button>',
			prevArrow: '<button type="button" class="btn btn-direction slick-prev"><span><i class="fa fa-long-arrow-left"></i></span></button>',
			slidesToShow: 1
		});
	}

	startSlider();
});

//  DOCUMENT READY END
//  -------------------------------------------------
//  -------------------------------------------------
